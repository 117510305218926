import Footer from "./Footer"
import Header from "./Header"
import SecondHeader from "./SecondHeader"

const Layout=({children})=>{
    return(
        <>
          <Header/>
          <SecondHeader/>
          {children}
          <Footer/>
        </>
    )
}

export default Layout