import React, { useState,useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

const Faq = () => {
  useEffect (() => {
    AOS.init({
      duration: 2000,
    });
  }, []);
  const [expanded, setExpanded] = useState([]); // Allow multiple expanded items

  const faqs = [
    {
      title: "Yes! Each user gets to build their own profile!",
      content:
        "The million dollar question! As independent contractors, you have the authority to track your team's production in any manner you'd like. We've designed this product with the sole intent of eliminating any sensitive or private information. We've also worked to ensure that there is zero point zero customer information in this program. The aspirants/team members will only be entering in numbers. For example...in the form to submit their production, you'll see 'Auto Quotes: ' They'll just enter '56' or whatever their quote/app numbers are. Since no SPI is in the program and it's only numbers, you're compliant to be able to use our tool to track their production.",
    },
    {
      title: "How will they enter in their results?",
      content:
        "Each team member or aspirant will enter in their results on a daily/week/monthly basis. Whatever frequency they prefer! Those results will instantly reflect where they stand and where your office stands locally, regionally and nationally.",
    },
    {
      title: "What is the National Sales Ranking?",
      content:
        "Each aspirant or team member will be instantly ranked regarding where they stand, by sales category. Those individual ranking numbers will be averaged to produce their National Sales Ranking number! The closer to #1, the better.",
    },
    {
      title: "Why would I want to do this?",
      content:
        "True sales people like to know where they stand. Aspirants are trying to build a resume for a possible agency one day. Prior to the invent of Sales Team Tracker, there was no program available to let them know, as well as their future interviewers, how they stood across their peers. We solve that problem. Many agents have that 'lead sales person' or persons who are also driven to succeed, but aren't sure how their peers are doing. We solved that. This program should encourage your aspirants or team members to produce at a higher level. It's another layer of accountability. JUST ONE MULTI-LINE SALE PER YEAR COVERS THEIR FEES. Just one! If you think you'd get one more sale out of your team members, then you have zero to lose.",
    },
    {
      title: "What if I lose a team member?",
      content:
        "Due to each aspirant or team member having a license, should they depart your agency, there are no refunds for the services. Hopefully, they've made that extra sale to cover the costs for a year and you've already made your money back!",
    },
    {
      title: "How do I add a new team member?",
      content:
        "As the agent, you'll have access to your admin dashboard. From there, you'll purchase a new license for them, enter in their email and they'll be able to enroll and get started!",
    },
    {
      title: "How are the numbers verified?",
      content:
        "Our system is on the honor system. A team member's sales numbers are a reflection upon the agent/agency. If there are intentionally inflated numbers, we pass that burden on to the agent to correct and manage. Should you see a number that doesn't seem right, drop that person a line! You'll be able to send them an email directly from their profile.",
    },
    {
      title: "Did you say profile?",
      content:
        "Yes! Each user gets to build their own profile! They have the option of uploading their picture and other minor details. It's a great digital resume for their future interviewers to review!",
    },
  ];

  const toggleAccordion = (index) => {
    if (expanded.includes(index)) {
      setExpanded(expanded.filter((i) => i !== index)); // Close the expanded item
    } else {
      setExpanded([...expanded, index]); // Open multiple FAQs
    }
  };

  return (
    <>
      <div className="py-5">
        <h1 className="justify-center text-center text-3xl md:text-5xl font-bold Poppins py-5 md:pt-10" data-aos="fade-down">
          FAQs
        </h1>
        <p  data-aos="fade-left" className="container md:px-16 m-auto space-y-4 pt-4 text-lg md:text-xl leading-6 ">
          Please reach us at{" "}
          <a href="mailto:sales@salesteamtracker.com" className="underline text-primary">
            sales@salesteamtracker.com
          </a>{" "}
          if you cannot find an answer to your question.
        </p>
        <div className="divide-y divide-slate-200 container mx-auto pb-4" data-aos="fade-right">
          <div className="md:px-16 pt-8">
            {faqs.map((faq, index) => (
              <div
                key={index}
                className={`w-full p-4 bg-gray-100 rounded-lg shadow-md transition-all duration-300 ease-in-out ${
                  expanded.includes(index) ? "mb-5" : ""
                }`}
              >
                <h2>
                  <div
                    id={`faqs-title-${index}`}
                    className="flex items-center justify-between w-full text-left text-lg md:text-xl font-semibold py-2 cursor-pointer"
                    onClick={() => toggleAccordion(index)}
                    aria-expanded={expanded.includes(index)}
                    aria-controls={`faqs-text-${index}`}
                  >
                    <span
                      className={`${
                        expanded.includes(index) ? "text-primary" : "text-black"
                      } transition-colors duration-300`}
                    >
                      {faq.title}
                    </span>
                    <svg
                      className={`fill-current shrink-0 ml-8 transition-transform duration-200 ease-out ${
                        expanded.includes(index) ? "rotate-180" : ""
                      }`}
                      width="16"
                      height="16"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4 6l4 4 4-4"
                        stroke="currentColor"
                        strokeWidth="2"
                        fill="none"
                      />
                    </svg>
                  </div>
                </h2>
                <div
                  id={`faqs-text-${index}`}
                  role="region"
                  aria-labelledby={`faqs-title-${index}`}
                  className={`overflow-hidden transition-all duration-300 ease-in-out ${
                    expanded.includes(index) ? "max-h-full opacity-100 mt-4 pb-3" : "max-h-0 opacity-0"
                  }`}
                >
                  <p className="text-sm md:text-base text-black">
                    {faq.content}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Faq;
