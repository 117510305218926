import { NavLink } from "react-router-dom";

/* eslint-disable jsx-a11y/anchor-is-valid */
const Header = () => {
  return (
    <>
      <div className=" hidden md:block bg-white border-gray-200 dark:bg-gray-900 dark:border-gray-700 " style={{ padding:'0px 60px'}}>
        <div className="flex flex-wrap items-center justify-between p-4">
          <NavLink
            to={'/'}
            className="flex items-center space-x-3 rtl:space-x-reverse"
          >
            <img src="/images/logo.png" className="h-14" alt="Flowbite Logo" />
          </NavLink>
        
          <div
            className="hidden w-full md:block md:w-auto"
            // id="navbar-dropdown"
          >
            <ul className="flex flex-col font-medium p-4 md:p-0 mt-4 gap-5 border border-gray-100 rounded-lg bg-gray-50 md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
              <li>
                <a
                  href="#"
                  className="block py-2 px-3 rounded md:bg-transparent md:p-0"
                  aria-current="page"
                >
                  <div className=" flex justify-center items-center gap-2">
                    <img src="/images/phone.png" alt="phone" />
                    <div className="flex justify-center items-center flex-col">
                      <h1  className="text-[16px] font-semibold" style={{color:'#878680'}}>Call anytime</h1>
                      <h1 className=" text-[19px] font-bold text-[#88111B]" style={{color:'#88111B'}}>+ 98 (000) - 9630</h1>
                    </div>
                  </div>
                </a>
              </li>

              <li>
                <a
                  href="#"
                  className="block py-2 px-3 rounded md:bg-transparent md:p-0"
                  aria-current="page"
                >
                  <div className=" flex justify-center items-center gap-2">
                    <img src="/images/email.png" alt="phone" />
                    <div className="flex justify-center items-center flex-col">
                      <h1  className="text-[16px] font-semibold" style={{color:'#878680'}}>Send email</h1>
                      <h1 className=" text-[19px] font-bold text-[#88111B]" style={{color:'#88111B'}}>ambed@agrios.com</h1>
                    </div>
                  </div>
                </a>
              </li>

              <li>
                <a
                  href="#"
                  className="block py-2 px-3 rounded md:bg-transparent md:p-0"
                  aria-current="page"
                >
                  <div className=" flex justify-center items-center gap-2">
                    <img src="/images/location.png" alt="phone" />
                    <div className="flex justify-center items-center flex-col">
                      <h1  className="text-[16px] font-semibold" style={{color:'#878680'}}>380 St Kilda Road</h1>
                      <h1 className=" text-[19px] font-bold text-[#88111B]" style={{color:'#88111B'}}>Melbourne, Australia</h1>
                    </div>
                  </div>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
