/* eslint-disable jsx-a11y/anchor-is-valid */

import { FaTwitter } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa6";
import { FaPinterest } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa6";
import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';


const Howitwork = () => {
  useEffect (() => {
    AOS.init({
      duration: 2000,
    });
  }, []);
  return (
    <>
      <div>
        <img src="/images/-title.png" alt="Decorative" className="" data-aos="fade-up" data-aos-duration="1000"/>
      </div>
      <div className="py-10 justify-center  flex items-center" data-aos="fade-down" data-aos-duration="1500">
        <img src="/images/Frame 33844.png" alt="Decorative" className="" />
      </div>
      <div className="container max-w-6xl mx-auto p-8 Manrope gap-12">
  <div className="flex flex-col md:flex-row justify-between items-start space-y-6 md:space-y-0 md:space-x-12">
    <div className="flex-1" data-aos="fade-right" data-aos-duration="2000">
      <h2 className="text-2xl font-bold text-gray-800 md:text-3xl">The Agent</h2>
      <p className="mt-4 text-gray-600">
        As the agent, your account is FREE to set up. You'll have complete access to the rankings and be able to see how other Aspirants or team members are doing.
      </p>
      <p className="mt-4 text-gray-600">
        After creating your profile, you will be able to sign up your Aspirant or Team Member for $99 a year! That's just the cost one multi-use household commission. We believe this tracker will generate you much, much more than that through higher accountability with your team.
      </p>
      <p className="mt-4 text-gray-600">
        Once you sign them up, they'll get an email with a link to login. After they complete that process, they'll be able to immediately start logging their results and see where they rank across the country, state, or city!
      </p>
      <p className="mt-4 text-gray-600">
        You will also be able to create public or private groups of team members so that you can have quick access to their scores/numbers and ranking within that group. This can be for study groups, sales territories, or any other collection of agents that you’d like to jump in with! Super simple!
      </p>
      <button className="mt-6 bg-primary text-white py-3 px-6 rounded-md hover:bg-red-700 transition-colors duration-300">
        Buy Now
      </button>
    </div>

    {/* Right Column: Card Content */}
    <div data-aos="fade-left" data-aos-duration="2500" className="flex-1 md:w-[30%] bg-white shadow-lg rounded-lg p-6 border border-t-[#EEC044] border-gray-200">
      <h3 className="text-xl font-semibold text-gray-800 mb-4">Services</h3>
      <p className="text-gray-600 font-extrabold">Healthy Food</p>

      <h3 className="mt-6 text-xl font-semibold text-gray-800 mb-4">Farmer</h3>
      <p className="text-gray-600 font-extrabold">Mike Hardson</p>

      <h3 className="mt-6 text-xl font-semibold text-gray-800 mb-4">Duration</h3>
      <p className="text-gray-600 font-extrabold">4.5 Months</p>

      <h3 className="mt-6 text-xl font-semibold text-gray-800 mb-4">Location</h3>
      <p className="text-gray-600 font-extrabold">Brooklyn, New York</p>

      {/* Social Media Links or Icons */}
      <div className="mt-6 flex space-x-4">
        <a href="#" className="text-gray-600 hover:text-gray-900">
        <FaFacebook color="" size={"22px"} />
          
        </a>
        <a href="#" className="text-gray-600 hover:text-gray-900">
          
          <FaTwitter color="" size={"22px"} />
           
        </a>
        <a href="#" className="text-gray-600 hover:text-gray-900">
        <FaInstagram color="" size={"22px"} />
        </a>
        <a href="#" className="text-gray-600 hover:text-gray-900">
        <FaPinterest color="" size={"22px"} />
        </a>
      </div>
    </div>
  </div>
</div>

      <div className="flex text-center justify-center">
      <h2 className="text-3xl font-extrabold mb-4 text-primary Manrope" data-aos="zoom-out" data-aos-duration="3000">The Aspirant or Team Member
      </h2>
      </div>
      <div className="max-w-6xl mx-auto p-8 grid grid-cols-1 lg:grid-cols-2 gap-12">
        {/* Left Section */}
        <div className="lg:w-full space-y-5">
         
          <div data-aos="fade-down-right">

         <img src="/images/image (1).png" alt="" />
          </div>
          <div data-aos="fade-up-right">

          <img src="/images/image (2).png" alt="" />
          </div>
        </div>

        {/* Right Section */}
        <div className="lg:w-full space-y-3">
          
          <div data-aos="fade-down-left">

          <img src="/images/image (3).png" alt="" />
          </div>
          <div data-aos="fade-up-left">

          <img src="/images/image 9.png" alt="" />
          </div>
        </div>
      </div>

      <div className="max-w-6xl mx-auto p-8 Manrope gap-12" data-aos="zoom-in">
        {/* Left Section */}
        <div className="lg:w-full">
          <h2 className="text-3xl font-bold mb-4">
            The Aspirant or Team Member
          </h2>
          <p className="text-lg mb-4">
            After you've signed up and purchased their licenses, they'll get an
            email asking them to set up their profile and how to start entering
            their sales data. Each day, week, or month, they will enter in their
            number of sales for the following categories:
          </p>
          <h1 className="text-2xl font-bold mb-4">Auto Quotes</h1>
          <h1 className="text-2xl font-bold mb-4">Raw New Auto Applications</h1>
          <h1 className="text-2xl font-bold mb-4">Total Fire Applications</h1>
          <h1 className="text-2xl font-bold mb-4">Life Applications</h1>
          <h1 className="text-2xl font-bold mb-4">Monthly Life Premium</h1>
          <h1 className="text-2xl font-bold mb-4">Health Applications</h1>
          <h1 className="text-2xl font-bold mb-4">Monthly Health Premium</h1>
          <p className="text-[16px] mb-4">
            They'll then click "update" and all their data will be uploaded to
            the database. From there, they (and you) will be able to see where
            they stand in your area, state or nation! Reports can also be
            generated and shared too! Custom lists coming soon for private
            aspirant groups, sales territories, market areas, or just
            peer-to-peer agency competitions.
          </p>
        </div>

        {/* Right Section */}
        <div className="lg:w-full">
          <h1 className="text-2xl font-bold mb-4">
            Welcome and User Instructions
          </h1>
          <p className="text-lg mb-4">
            Thanks for choosing to use Sales Team Tracker. The ONLY national
            agent aspirant and team member production tracking program on the
            market! By using this tool, you are able to see your rankings across
            all production lines as well as see the National Ranking of your
            team members or aspirants as well!
          </p>
          <p className="text-lg mb-4">
            We created the National Ranking to be similar to the multi-line
            ranking within your company. It takes the individual ranking number
            by line and averages those ranks to provide users with their overall
            average rank across the country! With our ltering feature, you can
            get those rankings by the day, week, month, quarter or year!{" "}
          </p>
          <p className="text-lg mb-4">
            {" "}
            We hope that this tracker encourages your aspirants or team members
            to protect more of the customer’s needs. It only takes one
            multi-line household to cover the cost of the tool! At anytime, you
            can shoot us an email with questions.{" "}
            <span className="text-primary">sales@salesteamtracker.com</span>
          </p>
        </div>

        {/* Additional Sections */}
        <div className="lg:w-full">
          <h1 className="text-2xl font-bold mb-4">Agent Instructions</h1>
          <p className="text-lg mb-4">
            Agent registration is always FREE! With this registration, you’ll be
            able to add your aspirants and team members for $99 per year, per
            person. You will also be able to view all the national rankings and
            sales numbers, as well as your own team’s dashboard! After you
            register and log in, you’ll be taken to your agency dashboard.
          </p>
          <p className="text-lg mb-4">
            After you register and login, you’ll be taken to your agency
            dashboard. Adding a team member
          </p>
          <p className="text-lg mb-4">
            On the left side of your screen, you’ll see a link that says,
            “Manage My Team.” Click that to begin the process of adding your
            team member
          </p>
          <p className="text-lg mb-4">
            In the top right, you will see “+ New Team Member.” By clicking
            this, you’ll be able to add your team member’s details. When you hit
            save, the payment system window will pop up and you’ll complete the
            secure transaction
          </p>
          <p className="text-lg mb-4">
            After payment is completed, you and your team member will get a
            registration email. It’s important that you share the password that
            you’ve created with your team member. They can then go in under the
            “Forgot my password” feature and change it to their own personal
            password
          </p>
          <p className="text-lg mb-4">
            Should a team member or aspirant leave your agency, you can also go
            in and deactivate that team member on the Manage My Team dashboard
          </p>
          <p className="text-lg mb-4">
            *NOTE: This tool uses the honor system. It’s up to you to ensure
            that your team is entering in accurate information. They will have
            24 hours to update their results if they make an error. Groups:
          </p>
          <p className="text-lg mb-4">
            One of the most exciting features of the tool is that you can create
            public and private groups! If you’re in a state-wide aspirant
            program, you can create a group of aspirants that you can
            automatically see their numbers and rankings. If you and a few
            agents want to create a private group, you can do that as well.
          </p>
          <p className="text-lg mb-4 text-primary">
            *Note: For private groups, one agent will be the Admin of that group
            and will approve any agents requesting to be a part of it.
          </p>
        </div>

        <div className="lg:w-full">
          <h1 className="text-2xl font-bold mb-4">
            Aspirant and Team Member Instructions
          </h1>
          <p className="text-lg mb-4">
            After your agent registers you for the program, you should get a
            registration email. You can click the login button and enter the
            program using your State Farm email address and the password that
            your agent created for you. You can also reset your password to your
            own using the Forgot My Password link.
          </p>
          <p className="text-lg mb-4">
            After logging in, you will be taken to your dashboard. From there,
            you will click the “Sales Report” link.
            <br />
            Once you’re in the Sales Report screen, you’ll click “+New Report.”
            This will allow you to enter in all the sales data for each day,
            week or month that you choose. You get to choose the frequency of
            your inputs. <br />
            *NOTE: This tool is on the honor system. Accurate sales information
            is up to YOU to input into the tool. If an error is made, you will
            have 24 hours to correct the mistake. After that, you’ll need to
            email us to correct the error, but that correction could be delayed.
            If you are an aspirant, your Sales Leader will want to verify your
            production with your agent. If your results are in ated or
            exaggerated, that could re ect poorly on you as the candidate as
            well as the agency. <br />
            After you click “Save” on the sales report, your dashboard will
            immediately be updated! Click the “Ranking” link to see where your
            results place you nationally by the day, week, month, quarter or
            year! <br />
            The “Group” link allows you to see where you rank in any public or
            private groups that your agent has placed you in . <br />
            We hope you enjoy the tool! Let us know if you have any questions or
            concerns. You can contact us at{" "}
            <span className="text-primary">sales@salesteamtracker.com.</span>
          </p>
        </div>

        <div className="lg:w-full">
          <h1 className="text-2xl font-bold mb-4">24/7 Tech Support</h1>
          <p className="text-lg mb-4 text-primary">
            Our team will work hard to ensure the functionality of the product.
            But if at any time, you have an issue, please just drop us a line at
            sales@salesteamtracker.com!
          </p>
        </div>
      </div>
    </>
  );
};

export default Howitwork;
