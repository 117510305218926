
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';
const ExploreProject = () => {
  useEffect(() => {
    AOS.init({
      duration: 2000, // Animation duration
      once: false, // Set to false to animate every time the element comes into view
      mirror: true, // Re-animate when scrolling up
      offset: 120, // Trigger animation 120px before element is in view
    });
  }, []);

  const images = ['/images/elements 1.png',"/images/elements 2.png","/images/elements 3.png","/images/elements 4.png"];
  return (
    <>
    <div className="flex justify-center px-2 md:px-16 md:mt-12 mt-4">
      <div className="container md:mt-9">
        <h1 className="text-center text-[27px] font-medium md:leading-[40px]">Recently Completed</h1>
        <h1 className="text-center md:leading-[65px] md:text-[55px] text-[#88111B] myFirstFont">Explore Projects</h1>
        <div className="grid md:grid-cols-4 grid-cols-1 gap-1  pt-8 mt-7"  data-aos="fade-up"> 
            {images?.map((e)=>{
                return(

                    <img src={e} className="rounded-sm" alt="df" />
                )
            })
            }
        </div>
      </div>
      </div>
    </>
  );
};

export default ExploreProject;
