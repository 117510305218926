import React, { useEffect } from 'react'
import Layout from '../Component/Layout/Layout'
import Faq from '../Component/Faq'


const Faqs = () => {
  useEffect(() => {
    window.scrollTo(0,0);
  }, []);
  return (
    <>
        <Layout>
            <Faq/>
         </Layout>
        </>
  )
}

export default Faqs
