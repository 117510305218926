import "./App.css";

import "flowbite";
// import 'flowbite/dist/flowbite.min.css';
import HomeBanner from "./Page/HomeBanner";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import GetStart from "./Page/GetStart";
import Buysetup from "./Page/Buy&setup";
import Agent from "./Page/Agent";
import Sale from "./Page/Sale";
import Faqs from "./Page/Faqs";
import Contactus from "./Page/Contactus";
import Howit from "./Page/Howit";
import { useEffect } from "react";

function App() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <BrowserRouter>
      <Routes>
        <>
          <>
            <Route path="/" element={<HomeBanner />} />
            <Route path="/getstart" element={<GetStart />} />
            <Route path="/buy&setup" element={<Buysetup />} />
            <Route path="/AgentLogin" element={<Agent />} />
            <Route path="/Sale" element={<Sale />} />
            <Route path="/Faq" element={<Faqs />} />
            <Route path="/contact-us" element={<Contactus />} />
            <Route path="/how-it-works" element={<Howit />} />
          </>
        </>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
