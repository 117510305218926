import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';

const OurIntroduction=()=>{
   useEffect(() => {
      AOS.init({
        duration: 2000, // Animation duration
        once: false, // Set to false to animate every time the element comes into view
        mirror: true, // Re-animate when scrolling up
        offset: 120, // Trigger animation 120px before element is in view
      });
    }, []);

    return(
        <>
        <div className="flex justify-center md:mt-8 mt-6">
        <div className="container flex justify-center py-2 px-4 md:px-10">
            <div className="grid md:grid-cols-2 gap-5 grid-cols-1 justify-center items-center"> 
         <div data-aos="fade-right">
            <img src="/images/outintro.png" alt="bg"/>
         </div> 
         <div>
            <h1 className="md:text-[28px] font-medium md:leading-[41px]">Our Introductions</h1>
            <h1 className=" font-normal text-[#88111B] md:text-[55px] myFirstFont md:leading-[65px]">Agriculture & Organic</h1>
            <h1 className="font-normal text-[#88111B] md:text-[55px] myFirstFont md:leading-[65px]">Product Farm</h1>
            <h1 className="font-extrabold text-[#C83425] md:text-[29px]  md:leading-[34px] md:mt-4 mt-2">Et volutpat in felis rutrum enim consequat.</h1>
            <p className=" font-medium text-[18px] md:leading-[34px] text-[#878680] md:mt-4 mt-2">Lorem ipsum dolor sit amet consectetur. Pharetra sodales ipsum eu nullam quis. Sed felis pellentesque id facilisis lorem turpis eget id.</p>
            <div className="flex flex-wrap md:gap-12 mt-8">
         <div className=" flex gap-2 flex-wrap" >
            <img src='/images/intro2.png' alt="f"/><p className="text-[#88111B] font-extrabold text-[22px] md:leading-[32px]">Growing<br/> fruits
            <br/>vegetables</p>
         </div>
         <div className=" flex gap-2 flex-wrap">
            <img src='/images/intro2.png' alt="f"/><p className="text-[#88111B] font-extrabold text-[22px] md:leading-[32px]">Tips for <br/> ripening
            <br/>
            your fruits</p>
         </div>
         </div>
         
         <p className=" text-[18px] mt-4 font-semibold leading-[34px] text-[#88111B]">Lorem Ipsum is not simply random text.</p>
         <p className="text-[18px] font-semibold leading-[34px] text-[#88111B]">Making this the first true generator on the internet.</p>
         <button className=" rounded-lg button-down mt-4">Discover More</button>


         </div>
         </div>
        </div>
        </div>
        </>
    )
}

export default OurIntroduction