import React, { useEffect } from "react";
import Layout from "../Component/Layout/Layout";
import Contact from "../Component/Contact";

const Contactus = () => {
  useEffect(() => {
    window.scrollTo(0,0);
  }, []);
  return (
    <>
      <Layout>
        <Contact />
      </Layout>
    </>
  );
};

export default Contactus;
