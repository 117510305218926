import { useEffect } from "react"
import ExploreProject from "../Component/ExploreProject"
import HomeBaner from "../Component/HomeBaner"
import FeaturedCard from "../Component/Layout/FeaturedCard"
import Layout from "../Component/Layout/Layout"
import OurFarm from "../Component/OurFarm"
import OurIntroduction from "../Component/OurIntroduction"
import Testimonial from "../Component/Testimonial"

const HomeBanner=()=>{
    useEffect(() => {
        window.scrollTo(0,0);
      }, []);
    return(
        <>
        <Layout>
            <div className=" overflow-x-hidden">
            <HomeBaner/>
        <FeaturedCard/>
        <OurIntroduction/>
        <ExploreProject/>
        <Testimonial/>
        <OurFarm/>
            </div>
        
         </Layout>
        </>
    )
}

export  default HomeBanner