import React, { useEffect } from 'react'
import Layout from "../Component/Layout/Layout"
import Howitwork from '../Component/Howitwork'
const Howit = () => {
  useEffect(() => {
    window.scrollTo(0,0);
  }, []);
  return (
    
    <div>
      <Layout>
        <Howitwork/>
      </Layout>
    </div>
  )
}

export default Howit
