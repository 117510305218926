/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'

const Sales = () => {
    const [passwordVisible, setPasswordVisible] = useState(false);

    // Toggle password visibility
    const togglePasswordVisibility = () => {
      setPasswordVisible(!passwordVisible);
    };
  
    return (
      <div
        className="h-screen flex justify-center items-center bg-cover bg-center"
        style={{ backgroundImage: `url('/images/image 4.png')`, backgroundRepeat: "no-repeat", backgroundSize: "cover" }}
      >
        <div
  className="w-[460px] h-[583px] flex items-center justify-center"
  style={{
    backgroundImage: `url('/images/Group 221.png')`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover"}}>
      
        <div className="bg-gray-100 bg-opacity-90 p-8 rounded-lg shadow-lg w-[390px]">
          <div className="text-center mb-8">
            <img src="/images/logo.png" alt="Barnyard Tracker" className="mx-auto mb-4 w-32" />
            <h2 className="text-2xl font-bold text-black">Sales Person Sign In</h2>
          </div>
          <p className="text-[#000000] py-3 font-normal text-base">Enter your details below.</p>
  
          <form>
            <div className="mb-4">
              <div className="relative">
                <input
                  type="email"
                  id="email"
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-primary focus:outline-none"
                  placeholder="Email"
                />
                <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                  <img src="/images/person.png" alt="Person Icon" />
                </div>
              </div>
            </div>
  
            <div className="mb-4">
              <div className="relative">
                <input
                  type={passwordVisible ? "text" : "password"} // Toggle between text and password
                  id="password"
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-primary focus:outline-none"
                  placeholder="Password"
                />
                <div className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer" onClick={togglePasswordVisibility}>
                  <img src={passwordVisible ? "/images/Vector.png" : "/images/Vector.png"} alt="Toggle Password Visibility" />
                </div>
              </div>
            </div>
  
            <div className="flex items-center justify-between mb-6">
              <label htmlFor="red-checkbox" className="flex items-center text-gray-600">
                <input
                  id="red-checkbox"
                  type="checkbox"
                  className="mr-2 border-gray-300 rounded checked:bg-primary checked:border-primary focus:ring-primary focus:ring-offset-0"
                />
                <span className="text-sm    ">Remember me</span>
              </label>
  
              <a href="#" className="text-sm text-black hover:underline">Forgot password?</a>
            </div>
  
            <button
  type="submit"
  className="w-[60%] bg-primary text-white py-2 rounded-lg hover:bg-red-600 transition duration-200 flex justify-center"
>
  Login
</button>
          </form>
  
          <p className="text-center text-black mt-6">
            Don't have an account? <a href="#" className="text-primary hover:underline">Get started</a>
          </p>
        </div>
        </div>
      </div>
    );
  };
  
export default Sales
