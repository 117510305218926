const HomeBaner=()=>{
    return(
        <>
         <div style={{backgroundImage:'url(/images/banner.png)'}} className="bg-cover bg-center h-screen flex items-center">
           <div className="flex  items-center px-5 md:px-30 lg:px-52">
            <div>
            <p className="font-semibold text-[18px] text-white montserrat">Welcome to Barnyard Tracker</p>
            <p className="text-3xl lg:text-[140px] text-white myFirstFont lg:leading-[125px]">Agriculture <span className="and">&</span></p>
            <p className="text-3xl lg:text-[140px] text-white myFirstFont lg:leading-[125px]">Eco Farming</p>
            <p className=" mt-3 text-white">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus,</p>
            <p className=" mt-2 text-white">Lorem ipsum dolor sit amet</p>
            <button className=" rounded-lg button-down mt-4">Discover More</button>

           </div>
           </div>
        </div>
        </>
    )
}
export default HomeBaner