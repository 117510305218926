import { useEffect } from "react";
import GetStarted from "../Component/GetStarted"
import Layout from "../Component/Layout/Layout"


const GetStart=()=>{
    useEffect(() => {
        window.scrollTo(0,0);
      }, []);
    return(
        <>
        <Layout>
            <GetStarted/>
         </Layout>
        </>
    )
}

export  default GetStart