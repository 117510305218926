import React from 'react'
import AgenLogin from '../Component/AgenLogin'

const Agent = () => {
  return (
    <div>
      <AgenLogin/>
    </div>
  )
}

export default Agent
