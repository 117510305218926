import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';

const FeaturedCard=()=>{
    useEffect(() => {
        AOS.init({
          duration: 2000,
        });
      }, []);

    return(
        <>
        <div className="flex justify-center md:px-40 px-3 md:mt-[-60px]">
        <div className="px-3 grid justify-center md:grid-cols-3 gap-12 grid-cols-1 container">
            <div className="rounded-lg shadow-2xl bg-[#FFFFFF] py-2" data-aos="fade-right">
             <h1 className=" font-normal text-[27px] py-2 px-3 text-center">Feature 01</h1>
             <h1 className=" font-extrabold text-[27px] text-center text-[#88111B]">            
                 We're using a</h1>
                 <h1 className=" font-extrabold text-[27px] text-center mt-[-10px] text-[#88111B]"> new technology</h1>
                 <div className="flex justify-center">
             <img  className="mt-1" src='/images/feature1.png' alt="fg"/>
             </div>
            </div>
            <div className="rounded-lg shadow-2xl bg-[#FFFFFF] py-1" data-aos="fade-up">
             <h1 className=" font-normal text-[27px] py-2 px-3 text-center">Feature 01</h1>
             <h1 className=" font-extrabold text-[27px] text-center text-[#88111B]">            
                 We're using a</h1>
                 <h1 className=" font-extrabold text-[27px] text-center mt-[-10px] text-[#88111B]"> new technology</h1>
                 <div className="flex justify-center">
             <img  className="mt-1" src='/images/feature1.png' alt="fg"/>
             </div>
            </div>
            <div className="rounded-lg shadow-2xl bg-[#FFFFFF] py-1" data-aos="fade-left">
             <h1 className=" font-normal text-[27px] py-2 px-3 text-center">Feature 01</h1>
             <h1 className=" font-extrabold text-[27px] text-center text-[#88111B]">            
                 We're using a</h1>
                 <h1 className=" font-extrabold text-[27px] text-center mt-[-10px] text-[#88111B]"> new technology</h1>
                 <div className="flex justify-center">
             <img  className="mt-1" src='/images/feature1.png' alt="fg"/>
             </div>
            </div>
        </div>
        </div>
        </>
    )
}

export default FeaturedCard 