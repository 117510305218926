import React from 'react'
import BuySetup from '../Component/Buy&Setup'

const Buysetup = () => {
  return (
    <div>
      <BuySetup/>
    </div>
  )
}

export default Buysetup
