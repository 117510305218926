import React from 'react'
import Sales from '../Component/Sales'

const Sale = () => {
  return (
    <div>
      <Sales/>
    </div>
  )
}

export default Sale
