import React from "react";
import { Send } from "lucide-react";
/* eslint-disable jsx-a11y/anchor-is-valid */

const Contact = () => {
  // const [passwordVisible, setPasswordVisible] = useState(false);
  // const togglePasswordVisibility = () => {
  //   setPasswordVisible(!passwordVisible);
  // };

  return (
    <div>
      <div className="grid grid-cols-1 md:grid-cols-2 Outfit h-auto md:h-[923.39px]">
        {/* Left Section with Form */}
        <div className="bg-[#E9F1EE] flex md:flex-row flex-col items-start relative pt-10 md:pt-20">
          <img
            src="/images/10.png.png"
            alt="Decorative"
            className="mr-2 relative md:absolute z-[100] pt-0 md:pt-[19rem] md:block hidden "
          />
          <div className="bg-white bg-opacity-90 p-4 md:p-8 rounded-lg h-auto md:h-[600.39px] shadow-lg w-full max-w-lg relative md:absolute left-0 md:left-[230px] mt-12 md:mt-28 z-10">
            <div className="mb-6 md:mb-8">
              <p className="text-primary text-sm md:text-base">
                Have Questions?
              </p>
              <p className="text-[24px] md:text-[50px] font-semibold">
                {" "}
                Send us a message
              </p>
            </div>

            {/* Form */}
            <form>
              <div className="mb-4">
                <div className="relative">
                  <input
                    type="text"
                    id="name"
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-primary focus:outline-none"
                    placeholder="Name"
                  />
                </div>
              </div>

              <div className="mb-4 grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="relative">
                  <input
                    type="email"
                    id="email"
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-primary focus:outline-none"
                    placeholder="Email"
                  />
                </div>
                <div className="relative">
                  <input
                    type="tel"
                    id="phone"
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-primary focus:outline-none"
                    placeholder="Phone"
                  />
                </div>
              </div>

              <div className="mb-4">
                <div className="relative">
                  <textarea
                    id="project-details"
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-primary focus:outline-none"
                    placeholder="Tell Us About Your Project*"
                  />
                </div>
              </div>

              <button
                type="submit"
                className="w-full md:w-[50%] bg-[#F7C35F] text-black font-bold py-2 rounded-lg transition duration-200 flex items-center justify-center gap-2"
              >
                <Send size={20} />{" "}
                {/* Ensure that the 'Send' icon component is imported correctly */}
                Get In Touch
              </button>
            </form>
          </div>
        </div>

        {/* Right Section with Contact Info */}
        <div
          className="flex items-center justify-center relative"
          style={{
            backgroundImage: `url('/images/__after.png')`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <div className="p-4 md:p-8 w-full mx-4 md:mx-20">
            <div className="mb-6 md:mb-8">
              <h2 className="text-[24px] md:text-[50px] font-bold text-white">
                Contact Information
              </h2>
              <p className="text-white py-3 font-normal text-sm md:text-base">
                Plan upon yet way get cold spot its week. Almost do am or limits
                hearts.
              </p>
            </div>

            <div>
              <p className="text-white pt-3 font-normal text-sm md:text-base">
                Hotline
              </p>
              <p className="text-white pb-1 font-normal text-sm md:text-base">
                +4733378901
              </p>
            </div>
            <div>
              <p className="text-white pt-3 font-normal text-sm md:text-base">
                Our Location
              </p>
              <p className="text-white py-1 font-normal text-sm md:text-base">
                55 Main Street, The Grand Avenue 2nd Block, New York City
              </p>
            </div>
            <div>
              <p className="text-white pt-3 font-normal text-sm md:text-base">
                Official Email
              </p>
              <p className="text-white py-1 font-normal text-sm md:text-base">
                info@email.com
              </p>
            </div>

            <div className="md:absolute relative bottom-0 right-0 p-8">
              <img
                src="/images/16.png.png"
                className="md:w-[288px] md:h-[300px]"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
