import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';

const Testimonial = () => {
  useEffect(() => {
    AOS.init({
      duration: 2000, // Animation duration
      once: false, // Set to false to animate every time the element comes into view
      mirror: true, // Re-animate when scrolling up
      offset: 120, // Trigger animation 120px before element is in view
    });
  }, []);
  return (
    <>
      <div className="flex justify-center md:mt-8 mt-6 bg-[#F8F7F0] md:py-52 py-8">
        <div className="container flex justify-center py-2 px-4 md:px-10">
          <div className="grid md:grid-cols-12 gap-5 justify-center items-center">
            <div className="md:col-span-5 col-span-12" data-aos="fade-right">
              <h1 className="md:text-[28px] font-medium md:leading-[41px]">
                Our Testimonials
              </h1>
              <h1 className="font-normal text-[#88111B] md:text-[55px] myFirstFont md:leading-[65px]">
                What They're
                <br />
                Talking About
                <br />
                Agrios
              </h1>
              <p className="font-medium text-[18px] md:leading-[34px] text-[#878680] md:mt-4 mt-2">
                Lorem ipsum dolor sit amet consectetur. Egestas netus aliquam
                fermentum metus. Enim suscipit neque fusce elementum ut.
              </p>
            </div>
            <div className="md:col-span-7 col-span-12 text-center md:text-left" data-aos="fade-left">
              <div className=" bg-[#FFFFFF] p-3 rounded-md py-8 px-8">
                <div className="flex  items-center flex-wrap  md:flex-nowrap justify-center">
                  <div className="flex flex-col">
                    <div>
                  <p className="text-[20px] text-[#878680] font-medium">
                    There are many variations of passages of available but the
                    majority have suffered alteration in some form by injected
                    humor or random word which don't look even.
                  </p>
                  </div>
                  <div className=" flex justify-between items-center my-3 flex-wrap">
                  <h1 className="text-[27px] font-extrabold leading-[34px] text-[#88111B]">Bonnie Tolbert</h1>
                 <div className="flex gap-1">
                 <img src='/images/star.png' className="w-[20px] h-[20px]" alt="star"/>
                 <img src='/images/star.png' className="w-[20px] h-[20px]" alt="star"/>

                 <img src='/images/star.png' className="w-[20px] h-[20px]" alt="star"/>
                 <img src='/images/star.png' className="w-[20px] h-[20px]" alt="star"/>
                 <img src='/images/star.png' className="w-[20px] h-[20px]" alt="star"/>
                 </div>
                 </div>
                </div>
                  <img src="/images/profile.png" alt="fd" />
                </div>
                {/* <div className="flex  flex-wrap md:flex-nowrap	lg:gap-30 xl:gap-96 gap-7">
                
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Testimonial;
